import React, { useContext } from 'react';
import { Col} from 'antd';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { QSMashupObject, BrandLogoImg, NavTerritory, TopNav, CenterV } from '@trinity-incyte/ui';
import {
	activeTeam as activeTeamAtom,
	primaryTeam as primaryTeamAtom,
	QSAppMetadataFamily,
	QlikUser as QlikUserAtom,
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { getTeamBrands, PathGenerators, teamHasFeature, toTitleCase } from '@trinity-incyte/utils'


const FieldSalesNav = ( props ) => {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId, items } = config.ids;
	const activeTeam = useRecoilValue(activeTeamAtom);
	const primaryTeam = useRecoilValue(primaryTeamAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const location = useLocation();
	const isMon = location.pathname.toUpperCase().indexOf('MONJUVI') > 0;
	const isPem = location.pathname.toUpperCase().indexOf('PEMAZYRE') > 0;
	const isJak = location.pathname.toUpperCase().indexOf('JAKAFI') > 0;
	const isZynyz = location.pathname.toUpperCase().indexOf('ZYNYZ') > 0;
	const isNiktimvo = location.pathname.toUpperCase().indexOf('NIKTIMVO') > 0;

	let currentLogo;
    const gridItems = [];
    const checkAccessToGeo = () => {
        const {
            REGIONANALYSIS: { showNation, showRegion } = {
                showNation: 'N',
                showRegion: 'N',
            },
        } = QlikUser?.features || {};
        return showNation === 'Y' || showRegion === 'Y';
    };
	
	if (isMon) {
		currentLogo = (<BrandLogoImg width="80px" height="auto" brand="MONJUVI" />);
	} else if (isNiktimvo) {
		currentLogo = (<BrandLogoImg width="80px" height="auto" brand="NIKTIMVO" />);
	} else if (isZynyz) {
		currentLogo = (<BrandLogoImg width="80px" height="auto" brand="ZYNYZ" />);
	} else if (isPem) {
		currentLogo = (<BrandLogoImg width="80px" height="auto" brand="PEMAZYRE" />);
		gridItems.push(
			<CenterV className='topNavItem' style={{flex: 1}} key='Pemazyre New Writers KPI'>
				<QSMashupObject
					isText size="tiny" color='#21ba45' inverted segmentColor
					contentClass="mini"
					labelStyle={{ fontSize: '0.9em' }}
					contentStyle={{ lineHeight: "1rem", width: '100%', background: 'transparent', borderRadius: '0.5em', padding: '0.25em', margin: '0.25em 0em'  }}
					mashupId={ids.get('Mosaic_Field Sales - Pemazyre_Kpi_Pemazyre New Writers')?.id}
					appId={appId}
				/>
			</CenterV>,
			<CenterV className='topNavItem' style={{flex: 1}} key='Pemazyre New Patients KPI'>
				<QSMashupObject
					isText size="tiny" color='#21ba45' inverted segmentColor
					contentClass="mini"
					labelStyle={{ fontSize: '0.9em' }}
					contentStyle={{ lineHeight: "1rem", width: '100%', background: 'transparent', borderRadius: '0.5em', padding: '0.25em', margin: '0.25em 0em' }}
					mashupId={ids.get('Mosaic_Field Sales - Pemazyre_Kpi_Pemazyre New Patients')?.id}
					appId={appId}
				/>
			</CenterV>
		);
	} else if (isJak) {
		currentLogo = (<BrandLogoImg width="80px" height="auto" brand="JAKAFI" />);
		gridItems.push(
			<CenterV className='topNavItem' style={{flex: 1}} key='Jakafi New Prescribers KPI'>
				<QSMashupObject
					isText size="tiny" color='#21ba45' inverted segmentColor
					contentClass="mini"
					labelStyle={{ fontSize: '0.9em', fontWeight:'normal' }}
					contentStyle={{ lineHeight: "1rem", width: '100%', background: 'transparent', borderRadius: '0.5em', padding: '0.25em', margin: '0.25em 0em'  }}
					mashupId={ids.get('Mosaic_Field Sales - Jakafi_kpi_New Prescribers (YTD)')?.id}
					appId={appId}
				/>
			</CenterV>,
			<CenterV className='topNavItem' style={{flex: 1}} key='Jakafi New Patients KPI'>
				<QSMashupObject
					isText size="tiny" color='#21ba45' inverted segmentColor
					contentClass="mini"
					labelStyle={{ fontSize: '0.9em', fontWeight:'normal' }}
					contentStyle={{ lineHeight: "1rem", width: '100%', background: 'transparent', borderRadius: '0.5em', padding: '0.25em', margin: '0.25em 0em' }}
					mashupId={ids.get('Mosaic_Field Sales - Jakafi_kpi_New Patients (YTD)')?.id}
					appId={appId}
				/>
			</CenterV>
		);
	}

	const header = activeTeam === 'OCNE' ? 'OCNE Reports' : 'Field Sales'

	// revised
	let dropdownItemsRevised = [];

  	// spacer 
	dropdownItemsRevised.push({ key: 'required head', text: header, type: 'header' });

	// Brand pages
	let brands = getTeamBrands(Config.App.teamToBrand, activeTeam);

	brands.forEach((brand) => {
		dropdownItemsRevised.push({ key: `brand=${brand}`, text: (<BrandLogoImg width="80px" height="auto" brand={brand} />), to: PathGenerators.FieldSalesPathGenerator(activeTeam,`/${toTitleCase(brand)}/Details/0`), type: 'item' })
	})

	// spacer
	dropdownItemsRevised.push({ key: 'required divider', type: 'divider' });

	// Region Analysis
	checkAccessToGeo() && dropdownItemsRevised.push({ key: 'Region Analysis', type: 'item', text: 'Region Analysis', to: PathGenerators.FieldSalesPathGenerator(activeTeam, '/RegionAnalysis/')});
	
	// Barriers Analysis
	teamHasFeature(Config.App.features, "Field Sales Barrier Analysis", activeTeam) && dropdownItemsRevised.push({ key: 'Barriers Analysis', type: 'item', text: 'Barriers Analysis', to: PathGenerators.FieldSalesPathGenerator(activeTeam, '/BarriersAnalysis/')});
	
	// Alerts Follow Up
    dropdownItemsRevised.push({ key: 'Alerts Follow Up', type: 'item', text: 'Alerts Follow Up', to: PathGenerators.FieldSalesPathGenerator(activeTeam, '/AlertsFollowUp/') });

	// Oregon Disclosure 
    dropdownItemsRevised.push({ key: 'Oregon Disclosure', type: 'item', text: 'Oregon Disclosure', to: PathGenerators.FieldSalesPathGenerator(activeTeam, '/Oregon/') });

	const content = (
		<TopNav
			showLastRX
			dropdownText={(<><span style={{marginRight: '1rem'}}>{header}</span>{currentLogo}</>)}
			dropdownItems={dropdownItemsRevised}
			showClear
			showFilters={(location.pathname.toUpperCase() !== PathGenerators.FieldSalesPathGenerator(activeTeam, '/REGIONANALYSIS').toUpperCase())}
			config={config}
		>
			{gridItems}
			<NavTerritory
				insideExistingNav
			/>
		</TopNav>
	);

	return content;
};

export default FieldSalesNav;
