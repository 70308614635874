import { QSAppMetadataFamily, QlikUser as QlikUserAtom } from "@trinity-incyte/recoil";
import { KPIBox, PDRPMessage, QSMashupObject, Redacted } from "@trinity-incyte/ui";
import React, { useContext, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import Config from "../../Config";
import ImgDigitalPull from '../../../assets/Images/digital_pull.png';
import ImgDigitalPush from '../../../assets/Images/digital_push.png';
import ImgFaceToFace from '../../../assets/Images/face_to_face.png';
import ImgPeerInteractionIcon from '../../../assets/Images/peer_to_peer.png';
import { List as ListAntd } from 'antd';
import QSTableTiles from "../Other/QSTableTiles";
import BarrierSegment from "../Other/BarrierSegment";
import { HCP360Context } from "@trinity-incyte/context";
import { StarFilled } from "@ant-design/icons";
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";
import { useMashup } from "@trinity-incyte/hooks";
import { BasicTableColumnRenderer } from "libs/ui/src/lib/Qlik/qscustom-table/basic-table/basic-table";
import QSField from "libs/ui/src/lib/Qlik/qsfield/qsfield";
declare const Mosaic: MosaicGlobal;

const config = Config.Qlik.Prof360;
const { appId } = config.ids;
let dateValue = "";



export const HCP360NiktimvoOverview = {
    'NIKTIMVO My MISSION Support': {
        component: (props) => {

            const QlikUser = useRecoilValue(QlikUserAtom);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;

            const suppressSalesData = QlikUser?.suppressSalesData;
            return (
                <KPIBox customClass="kpibox kpi-threesixty">
                    {suppressSalesData ? <Redacted titleProp={"MyMISSION Support"} /> :
                        <>
                            <div className="header-threesixty">{"MyMISSION Support"}</div>
                            <QSMashupObject
                                appId={appId}
                                customClass="basicTable"
                                mashupId={ids.get('Mosaic_HCP 360_Main - Niktimvo_MyMissionSupport')?.id}
                                isBasicTable
                                compactMargin
                                compact
                                {...props}
                            />
                        </>
                    }
                </KPIBox>
            )
        }
    },
    'NIKTIMVO Barriers Concept': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
                <KPIBox customClass="kpibox kpi-orange">
                <div className="header-threesixty" style={{color:'#FF8000'}}>{"Barriers"}</div>
                <div style={{padding:`0px 2px 0px 2px`,  width:'100%'}}>
                    <BarrierSegment appId={appId} mashupName={'Mosaic_HCP 360_Main - Niktimvo_Barrier Segmentation'} customContainerClass="barrierContainerClass" customClass="barrierSegments" />
                </div>
                </KPIBox>
            )}
        },
    'NIKTIMVO Multi Channel Engagement': {
    component: (props) => {
        const metadata = useRecoilValue(QSAppMetadataFamily(appId));
        const { IDsTable: ids } = metadata;
        return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/MCE/${props.profId}`}>
            <div className="header-threesixty-linked header-threesixty-linked-table">{"Multi Channel Engagement"}</div>
            <div style={{display:'flex', width:'100%', margin:'8px 0px 0px 0px', flexWrap:'wrap'}}>
                <div style={{width:'50%', padding:'0'}}>
                <QSMashupObject isText progressIconLeft={ImgFaceToFace} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Face to Face AFF KPI')?.id} />
                </div>
                <div style={{width:'50%', padding:'0'}}>
                <QSMashupObject isText progressIconLeft={ImgPeerInteractionIcon} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Peer Interaction KPI')?.id} />          
                </div>
                <div style={{width:'50%', padding:'0'}}>
                <QSMashupObject isText progressIconLeft={ImgDigitalPush} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Digital Push KPI')?.id} />          
                </div>
                <div style={{width:'50%', padding:'0'}}>
                <QSMashupObject isText progressIconLeft={ImgDigitalPull} compact compactMargin appId={appId} mashupId={ids.get('Mosaic_HCP 360 - Overview_Prof Digital Pull KPI')?.id} />          
                </div>
            </div>
            </KPIBox>
        )}
    },
    'NIKTIMVO Diagnosis Claims Quintile': {
    component: (props) => {
        const metadata = useRecoilValue(QSAppMetadataFamily(appId));
        const { IDsTable: ids } = metadata;
        const { FieldsTable: fields } = metadata;
    return (
        <KPIBox customClass="kpibox kpi-threesixty">
        <div style={{textAlign:'left'}}>
            <div className="header-threesixty" style={{flex: 1, display: 'flex'}}>
                <div style={{flex: 1}}>{"Diagnosis Claims Quintile"}</div>
                <div style={{color:'#666', fontWeight:'normal'}}>1-Lowest, 5-Highest</div>
            </div>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}></div>
                <div style={{textAlign: "right"}}>
                    <div style={{fontWeight: 700}}>MPNs</div>
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 -Overview_MF Quintile KPI'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 -Overview_PV Quintile KPI'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                </div>
                <div style={{flex: 1}}></div>
                <div style={{textAlign: "right"}}>
                    <div style={{fontWeight: 700}}>GVHD</div>
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 - Overview_Acute GVHD Quintile'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 - Overview_Chronic GVHD Quintile'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                    <QSField appId={appId} mashupName={'Mosaic_HCP 360 - Overview_Total GVHD Quintile'} titleStyle={{display:"inline"}} valueStyle={{display:"inline"}} />
                </div>
                <div style={{flex: 1}}></div>
            </div>
        </div>
        </KPIBox>
    )}
    },
    'NIKTIMVO Connections': {
    component: (props) => {        
        const mashupData = useMashup(appId, 'Mosaic_HCP 360 - Overview_HCP Connections KPI');
        const numConnections = mashupData?.table?.rows[0]?.cells[0]?.qText ?? "-";
        return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/Connections/${props.profId}`}>
                <div className="header-threesixty text-center">{"Connections"}</div>
                <div className="text-center qsKPIValue">{numConnections}</div>
            </KPIBox>
        )
    }
    },
    'NIKTIMVO Alerts': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty" canClick to={`/HCP360/IncyteActivity/Alerts/${props.profId}`}>
                <div className="header-threesixty-linked">{"Alerts"}</div>
                <div style={{borderRadius:'4px', paddingLeft:'20px', width:'100%'}}>
                    <QSTableTiles appId={appId} mashupId={ids.get('Mosaic_HCP 360_Main - Niktimvo_Alert Tiles')?.id} labelColumnId={0} valueColumnId={1} />
                </div>
            </KPIBox>
            )
        }
    },
    'NIKTIMVO HCP Activity': {
    component: (props) => {
        const metadata = useRecoilValue(QSAppMetadataFamily(appId));
        const { IDsTable: ids } = metadata;
        return (
        <KPIBox canClick to={`/HCP360/IncyteActivity/Interactions/${props.profId}`} customClass="kpibox kpi-threesixty">
            <div className="header-threesixty-linked header-threesixty-linked-table">{"HCP Activity"}</div>
            <QSMashupObject
            appId={appId}
            objectKey='UpVLTw'
            customClass="basicTable"
            mashupId={ids.get('Mosaic_HCP 360 - Overview_HCP Most Recent Activity')?.id}
            isBasicTable
            compact
            compactMargin
            {...props}
        />
        </KPIBox>
    )}
    },
    'NIKTIMVO GVHD Landscape': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
            <KPIBox customClass="kpibox kpi-threesixty">
            <div className="header-threesixty">{"GVHD Landscape"}</div>
            <QSMashupObject
                    appId={appId}
                    objectKey='Mosaic_HCP 360_GVHD_Landscape'
                    customClass="basicTable"
                    mashupId={ids.get('Mosaic_HCP 360_GVHD Landscape')?.id}
                    isBasicTable
                    compact
                    compactMargin
                    {...props}
                />
            </KPIBox>
        )}
    },
}

export default HCP360NiktimvoOverview;