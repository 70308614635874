import React, { useContext } from 'react';
import { RGLGrid as Grid, NavTerritory, TopNav } from '@trinity-incyte/ui';
import { ConfigContext } from '@trinity-incyte/context';

const Calendar = () => {
  const Config = useContext(ConfigContext);

	const defaultLayout = {
		xs: [{
			w: 24,
			h: 16,
			x: 0,
			y: 0,
			i: '/Events/Calendar',
			props: {
				config: Config.Qlik.FieldSales,
			},
			moved: false,
			static: true,
		}],
	};

	const content = (
		<>
			<TopNav
				dropdownText={(<span>Events</span>)}
				dropdownItems={[
					{ text: 'Events', type: 'header' },
					{ text: 'Recent', to: '/Events/Recent' },
					{ text: 'Upcoming', to: '/Events/Upcoming' },
					{ text: 'Calendar', to: '/Events/Calendar' },
				]}
				config={Config.Qlik.FieldSales}
			>
				<NavTerritory />
			</TopNav>
			<Grid
				title="Calendar"
				layout={defaultLayout}
			/>
		</>
	);

	return ( content );
};

export default Calendar;
