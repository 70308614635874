import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { ConfigContext } from '@trinity-incyte/context';
import {
    activeTeam as activeTeamAtom,
    QlikUser as QlikUserAtom,
    QSAppMetadataFamily,
    UserTerritory as UserTerritoryAtom
} from '@trinity-incyte/recoil';
import { Card, Col, Popover, Row, Statistic } from 'antd';
import React, { useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';
import HCP360Cell from '../Addons/hcp360-cell/hcp360-cell';
import NavTerritory from '../Other/nav-territory/nav-territory';
import SimpleCarousel from '../Other/simple-carousel/simple-carousel';
import QSMashupObject from '../Qlik/qsmashup-object';
import { TopNav } from '../Groups/top-nav/top-nav';
import { CenterV } from '@trinity-incyte/ui';

declare var window: any;

const cardStyle = {
	background: 'transparent',
	border: '1px solid #F0F0F0',
	borderRadius: '0 1rem',
};

const cardHeadStyle = {
	color: 'aliceblue',
	fontWeight: 'bold',
	fontSize: '1.2rem',
	border: 'unset',
};

const cardBodyStyle = {
	padding: '0 12px',
};

const ComparisonKPI = (props) => {
	const {
		style,
		title,
		value,
		valueStyle,
		headStyle,
		bodyStyle,
		prefix,
		suffix,
		onClick,
	} = props;

	return (
		<Card
			hoverable
			bordered
			onClick={onClick}
			size="small"
			style={{ ...cardStyle, ...style }}
			headStyle={{ ...cardHeadStyle, ...headStyle }}
			bodyStyle={{ ...cardBodyStyle, ...bodyStyle }}
			title={title}
			extra={
				<Statistic
					value={value}
					valueStyle={{ ...valueStyle, fontSize: '1.6rem' }}
					precision={1}
					prefix={prefix}
					suffix={suffix}
				/>
			}
		/>
	);
};

const getStyle = (value) => {
	const retVal = {
		color: 'aliceblue',
	};

	if (value === 0) {
		retVal.color = '#B3B3B3';
	} else if (value < 0) {
		retVal.color = '#e62231';
	} else {
		retVal.color = '#52b728';
	}

  return retVal;
};

const getPrefix = (value) => {
	let retVal;

	if (value === 0) {
		retVal = null;
	} else if (value < 0) {
		retVal = <ArrowDownOutlined />;
	} else {
		retVal = <ArrowUpOutlined />;
	}

    return retVal;
};

/* eslint-disable-next-line */
export interface KPINavHighlightProps {}

export function KPINavHighlight(props: KPINavHighlightProps) {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const activeTeam = useRecoilValue(activeTeamAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [KPI1, set_KPI1] = useState(0);
	const gridItems = [];

	const carouselHeight = window.innerWidth < 1500 ? '60vh' : '58vh';
	const tableHeight = window.innerWidth < 1500 ? '57vh' : '55vh';
  
	const topFivePopover = (
		<SimpleCarousel
		  carouselProps={{
				dots: { className: 'topten-dots' },
				style: { height: carouselHeight, width: '80vw' },
			}}
		  slideTitles={[
				'Top 5 Gainers',
				'Top 5 Decliners',
				'Monjuvi Experience',
		  ]}
		  slides={[
				<QSMashupObject
					isTable
					showExports
					skipProfHeaders
					appId={appId}
					mashupId={ids.get('Mosaic_Main - Monjuvi_table_Top 5 Gainers - C13W')?.id}
					contentStyle={{ height: tableHeight, paddingBottom: '0' }}
					tableProps={{
						colProps: [{
							profAddon: (props) => (null),
						}],
						colOverrides: [{
							columns: [0],
							hStyles: { width: "16rem" },
						}, {
							columns: [1, 2, 3, 4, 5],
							hStyles: { width: "4rem" },
						}, {
							columns: [6],
							hStyles: { width: "8rem" },
						}]
					}}
				/>,
				<QSMashupObject
					isTable
					showExports
					skipProfHeaders
					appId={appId}
					mashupId={ids.get('Mosaic_Main - Monjuvi_table_Top 5 Decliners - C13W')?.id}
					contentStyle={{ height: tableHeight, paddingBottom: '0' }}
					tableProps={{
						colProps: [{
							profAddon: (props) => (null),
						}],
						colOverrides: [{
							columns: [0],
							hStyles: { width: "16rem" },
						}, {
							columns: [1, 2, 3, 4, 5],
							hStyles: { width: "4rem" },
						}, {
							columns: [6],
							hStyles: { width: "8rem" },
						}]
					}}
				/>,
				<QSMashupObject
					isTable
					showExports
					skipProfHeaders
					appId={appId}
					mashupId={ids.get('Mosaic_Main - Monjuvi_table_Monjuvi Experience')?.id}
					contentStyle={{ height: tableHeight, paddingBottom: '0' }}
					tableProps={{
						colProps: [{
							addon: HCP360Cell,
							profAddon: (props) => (null),
						}],
						colOverrides: [{
							columns: [0],
							hStyles: { width: "16rem" },
						}, {
							columns: [1, 2, 3, 4, 5],
							hStyles: { width: "4rem" },
						}, {
							columns: [6],
							hStyles: { width: "rem" },
						}]
					}}
				/>
		  ]}
		/>
	);

	// Add the comparison KPIs & Top Ten
	if (activeTeam === 'HO3') {
		gridItems.push(
			(
				<CenterV className='topNavItem' style={{flex: 1}}>
					<QSMashupObject
						appId={appId}
						mashupId={ids.get('Mosaic_Main - Monjuvi_kpi_C13W v. P13W')?.id}
						noShow
						customFormatter={({val1}) => {
							set_KPI1(parseFloat(val1));
						}}
					/>
					<Popover
						trigger="click"
						placement="bottomLeft"
						content={(
							<QSMashupObject
								appId={appId}
								mashupId={ids.get('Mosaic_Main - Monjuvi_table_C13W v. P13W')?.id}
								isTable
								contentStyle={{height: '12rem'}}
								tableProps={{
									colOverrides: [{
										columns: [0, 1],
										hStyles: { width: "8rem" },
									},{
										columns: [2, 3],
										hStyles: { width: "6rem" },
									}]
								}}
							/>
						)}
					>
						<ComparisonKPI
							title="C13W v. P13W"
							value={KPI1}
							valueStyle={getStyle(KPI1)}
							prefix={getPrefix(KPI1)}
							suffix="%"
							onClick={() => {
								window.analytics?.track('Button Clicked', { text: 'C13W v. P13W', context: 'Main Nav - C13W v. P13W KPI Button Clicked' })
							}}
						/>
					</Popover>
				</CenterV>
			), (
				<CenterV className='topNavItem' style={{flex: 1}}>
					<Popover
						trigger="click"
						placement="bottom"
						style={{ padding: '0' }}
						content={topFivePopover}
					>
						<ComparisonKPI
							title="PERFORMANCE HIGHLIGHTS"
							style={{ border: '2px solid #66cc99', textAlign: "center" }}
							headStyle={{ color: '#66cc99' }}
							value={' '}
							onClick={() => window.analytics?.track('Button Clicked', { text: "Performance Highlights", context: 'Main Nav - Performance Highlights KPI Button Clicked' })}
							prefix={null}
							suffix={null}
						/>
					</Popover>
        </CenterV>
			)
		)
	}

	const content = (
		<TopNav
			config={config}
			showLastRX
			showFilters={(QlikUser?.features?.HOME?.showFilters === 'Y')}
		>
			{gridItems}
			<NavTerritory
				insideExistingNav
			/>
		</TopNav>
	);

	return content;
};

export default KPINavHighlight;
