import CryptoJS from 'crypto-js';
import {convertTeam, getPrimaryBrandForTeam} from '@trinity-incyte/utils';
import { MosaicGlobal, QlikAppInstance } from '@trinity-incyte/api-interfaces';
export interface useUserDataProps {
  Config: any;
  app: QlikAppInstance;
  environment: any;
  set_activeTeam: any;
  set_primaryTeam: any;
  set_QlikUser: any;
  set_UserTerritory: any;
  set_activeBrand: any;
  set_geographyFlag: any;
  set_defaultSelection: any;
}

declare const window: any;
declare const Mosaic: MosaicGlobal;

export function useUserData ({ Config, app: fieldSalesApp, environment, set_activeTeam, set_primaryTeam, set_QlikUser, set_UserTerritory, set_activeBrand, set_geographyFlag, set_defaultSelection }: useUserDataProps) {    const glob = Mosaic.QlikAPI.getGlobal(Config.Qlik.config);
    const userDataPromise = new Promise<void>((resolveUserData, rejectUserData) => {
      let au, auForHash, userHash;
      glob.getAuthenticatedUser((authUser) => {
        au = authUser.qReturn;
        auForHash = au + environment.userEnvironmentSalt; // salt is blank for production to preserve legacy data
        userHash = CryptoJS.SHA256(auForHash).toString();  
      })
      .then(() => {
        // Get user data
        // TODO: close this after data fetch
        const username = `${au.split('; ')[0].split('=')[1]}\\${au.split('; ')[1].split('=')[1]}`;
        const fieldSalesPermittedExpression = { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['FieldSalesPermitted']}]` }; 
        const userDataGeneric = {
          empType: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['User Emp Type']}]` },
          accessType: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['User Access Type']}]` },
          features: {
            APP: {
              homePermitted: fieldSalesPermittedExpression,
              fieldSalesPermitted: fieldSalesPermittedExpression,
              eventsPermitted: fieldSalesPermittedExpression,
              HCP360Permitted: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['HCP360Permitted']}]` },
              HCO360Permitted: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['HCO360Permitted']}]` },
              ListBuilderPermitted: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['ListBuilderPermitted']}]` },
              SalesDataPermitted: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['SalesDataPermitted']}]` },
              // trainer: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['xxx']}]` },
              ChatBotPermitted: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['ChatBot']}]` },
            },
            HOME: {
              showFilters: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['HOME - Show Filters']}]` },
            },
            REGIONANALYSIS: {
              showNation: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['REGIONANALYSIS - Show Nation']}]` },
              showRegion: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['REGIONANALYSIS - Show Region']}]` },
            },
          },
          masqueradeName: { qStringExpression: `=ONLY({1<[SA_USERID]={${username}}>} SA_EMP_NAME)` },
          team: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['User Team']}]` },
          primaryTeam: { qStringExpression: `=[${Config.Qlik.FieldSales.ids.fields['User Primary Team']}]` },
        };
  
        Object.assign(userDataGeneric, Config.Qlik.FieldSales.userDataFields);
  
        const processUserDataPromise = fieldSalesApp.createGenericObject(userDataGeneric).then((obj) => {
          const reply = obj.layout;
          let { ...userData } = reply;

          userData.pageVisibility = {
            home: true,
            fieldSales: true,
            events: true,
            hcp360: true,
            hco360: true,
            listBuilder: true,
            trainer: true,
            chatBot: true,
          }
          
          userData.pageVisibility.home = userData.features?.APP?.homePermitted == 'Y'
          userData.pageVisibility.fieldSales = userData.features?.APP?.fieldSalesPermitted == 'Y'
          userData.pageVisibility.events = userData.features?.APP?.eventsPermitted == 'Y'
          userData.pageVisibility.hcp360 = userData.features?.APP?.HCP360Permitted == 'Y'
          userData.pageVisibility.hco360 = userData.features?.APP?.HCO360Permitted == 'Y'
          userData.pageVisibility.listBuilder = (userData.features?.APP?.ListBuilderPermitted == 'Y')
          userData.pageVisibility.chatBot = (userData.features?.APP?.ChatBotPermitted == 'Y')
  
          userData.suppressSalesData = userData.features.APP.SalesDataPermitted == "N";
  
          // filling out details for user analytics
          window.analytics?.identify(userHash, {
            accessType: obj.layout.accessType,
            empType: obj.layout.empType
          });
  
          /*
           * Parse through the QlikUser data and set semantic properties for use throughout the app
           * This is a one-time operation, i.e. DOES NOT UPDATE FOR MASQUERADE
           */
          const userTeams = userData.team.toString().split(',') as Array<string>;
          const primaryTeam: any = convertTeam(userData.primaryTeam);
          set_primaryTeam(primaryTeam);
          set_activeTeam(primaryTeam);
  
          let activeBrand = getPrimaryBrandForTeam(Config.App.teamToBrand, primaryTeam);
          set_activeBrand(activeBrand);
  
          // Directors (DBD, OBD) and Admins see the team selector
          if (userTeams.length >= 2) {
             userData.showTeamSelector = userTeams.map((val) => (convertTeam(val)));
          } else {
             userData.showTeamSelector = false;
          }
  
          userData.isTrainer = userData.accessType === "TRAINER"
          userData.pageVisibility.trainer = userData.isTrainer
          userData.showMasquerade = (userData.accessType === 'ADMIN' && userData.pageVisibility?.fieldSales && userData.isTrainer === false)
          userData.showSidebarFilters = !userData.isTrainer
  
          // DERM - if the user is DTS don't show the avg calls per day page or the TRx Report Territory filter
          if (userData.empType !== 'DTS') {
            userData.showTerritoryComparison = true
            userData.showAvgCallsPerDay = true;
            userData.showTRxTerritoryFilter = true;
          } else {
            userData.showTerritoryComparison = false;
            userData.showAvgCallsPerDay = false;
            userData.showTRxTerritoryFilter = false;
          }
  
          // Only admins have myGeo checkbox set to false
          if (userData.accessType === 'ADMIN') {
            set_geographyFlag(false);
          }
  
          // DERM - if the user is DBD or DTS - don't show the region checkbox or the TRx Report Region filter
          if ((userData.empType === "DTS") || (userData.empType === "DBD")) {
            userData.showTRxRegionFilter = false;
            userData.showMyGeoControl = false;
            set_geographyFlag(true);
          } else if (userData.features.APP.HCP360Permitted === 'Y') {
            userData.showTRxRegionFilter = false;
            userData.showMyGeoControl = false;
          } else {
            userData.showMyGeoControl = true;
            userData.showTRxRegionFilter = true;
          }
  
          if (Config.App.name === 'MosaicDerm') {
            userData.showMyGeoControl = false;
            userData.pageVisibility.home = true
            userData.pageVisibility.fieldSales = true
            userData.pageVisibility.events = true
            userData.pageVisibility.hcp360 = true
            userData.pageVisibility.listBuilder = true
          }
  
          set_QlikUser({
            authenticatedUser: au,
            ...userData,
          });
          const selection = {...Config.Qlik.FieldSales.defaultSelections};
      
          set_defaultSelection(selection);
      
          if (!window.WalkMeData.Role && userData.empType) {
            window.WalkMeData.Role = userData.empType;
          }

          if (!Object.values(userData.pageVisibility).includes(true)) {
            rejectUserData()
          }
        });
  
        const userTerritoryPromise = fieldSalesApp.createGenericObject(
          Config.App.teamToBrand.reduce((acc, team) => { 
            acc[team.teamName] = { qStringExpression: team.userTerritoryField };
            return acc;
          }, {})
        , (rawTeamTerritories) => {
          let teamTerritories = Config.App.teamToBrand.reduce((acc, team) => {
            acc[team.teamName.toLowerCase()] = rawTeamTerritories[team.teamName];
            return acc;
          }, {});
          set_UserTerritory(teamTerritories);
        })
      
        Promise.allSettled([processUserDataPromise, userTerritoryPromise]).then(() => {
          resolveUserData()
        })
      });
    })

    return userDataPromise
}

export default useUserData;