import { ShrinkOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState, useResetRecoilState, useRecoilState } from 'recoil';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { RGLGrid as Grid, BottomSlider, LeftSlider, NavGroupMosaic } from '@trinity-incyte/ui';
import {
    connectedProfileRefState,
    screenOrientationState,
    sideNavIsExpandedState,
    connectionExistState,
    activeTeam as activeTeamState,
} from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { ConfigContext } from '@trinity-incyte/context';

declare const Mosaic: MosaicGlobal;

const Connections = ( props ) => {
	const { profId } = props;
    const isProfSelected = use360ProfSelection( profId );
    const activeTeam = useRecoilValue(activeTeamState);

    const set_connectionExist = useSetRecoilState(connectionExistState);
    const connectedProfRef = useRef();
    const [,setConnectedProfRef] = useRecoilState(connectedProfileRefState)
    const Config = useContext(ConfigContext);
    const config = Config.Qlik.Prof360;
    const { appId } = config.ids;
    const app = Mosaic.Qlik.app[appId];
    const tempProfId = app.selections?.find(sel => sel.fieldName === "DCA.PROF_FULL_NAME_WITH_ID")?.selectedValues?.[0].qName.split('(')[1]?.split(')')[0]
    
    useEffect(() => {
        return () => {
            set_connectionExist(false);
        }
    }, []);
    useEffect(() => {
        set_connectionExist(!!tempProfId);
        return () => set_connectionExist(false);
    }, [tempProfId]);
    useEffect(() => {
        setConnectedProfRef(connectedProfRef.current);
        return () => setConnectedProfRef(undefined);
    }, [connectedProfRef.current]);
	let defaultLayout;

	if ( profId === false ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Message Box',
				props: {
					color: 'green',
					text: 'Connections',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId === '-' ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Message Box',
				props: {
					color: 'orange',
					text: 'Please select one HCP to view the overview.',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId !== false && !isProfSelected ) {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Loader',
				props: {
					color: 'blue',
					text: 'Loading...',
				},
				moved: false,
				static: true,
			}],
		};
	} else if ( profId !== false && isProfSelected ) {
		defaultLayout = {
            responsive: [
                {
                    style: {height: '100%'},
                    w: 5,
                    i: 'Mosaic Overview Prof Box',
                    props: { profId, key: `profBox-${profId}` },
                },
                {
                    w: 19,
                    style: {height: '100%'},
                    items: [
                        {
                            style: {height: '50%'},
                            items: [
                                {
                                    w: 7,
                                    i: 'Summary of Connections',
                                    props: {
                                        showExports: false,
                                        tableProps: {
                                            colOverrides: [
                                                {
                                                    columns: [0],
                                                    hStyles: { width: '10.5rem' },
                                                },
                                                {
                                                    columns: [1],
                                                    hStyles: { width: '6rem' },
                                                },
                                            ],
                                        },
                                    },
                                },
                                {
                                    w: 17,
                                    i: 'Connections Top Table',
                                    props: {
                                        showExports: true,
                                        tableProps: {
                                            colOverrides: [
                                                {
                                                    columns: [0],
                                                    hStyles: { width: '34rem' },
                                                },
                                                {
                                                    columns: [1],
                                                    hStyles: { width: '11rem' },
                                                },
                                                {
                                                    columns: [2],
                                                    hStyles: { width: '7rem' },
                                                },
                                            ],
                                        },
                                    },
                                },
                            ]
                        },
                        {
                            style: {height: '50%', margin: 0, padding: '8px 0 0'},
                            items: [
                                tempProfId ? {
                                    w: 7,
                                    i: 'Prof Connection Details',
                                    style: {padding: '0 5px 0 0px'},
                                    props: {
                                        tempProfId,
                                        connectedProfRef,
                                    },
                                }: {w: '0'},
                                {
                                    w: tempProfId ? 17 : 24,
                                    style: {padding: tempProfId ? '0 0 0 3px': 0},
                                    i: 'Connections Bottom Table',
                                    props: {
                                        showExports: true,
                                        profId,
                                        activeTeam,
                                        groupHeads:  activeTeam === 'HO3' ? [
                                            {
                                                columns: [2],
                                                title: 'Speaker',
                                            },
                                            {
                                                columns: [3],
                                                title: 'Speaker Program Attendee ',
                                            },
                                            {
                                                columns: [4, 5, 6, 7],
                                                title: 'External Expert',
                                            },
                                            {
                                                columns: [8, 9, 10, 11],
                                                title: 'Incyte Investigator',
                                            },
                                            {
                                                columns: [12, 13, 14, 15],
                                                title: 'External Investigator',
                                            },
                                        ] : [
                                            {
                                                columns: [2, 3],
                                                title: 'Speaker',
                                            },
                                            {
                                                columns: [4, 5],
                                                title: 'Speaker Program Attendee ',
                                            },
                                            {
                                                columns: [6, 7, 8, 9],
                                                title: 'External Expert',
                                            },
                                            {
                                                columns: [10, 11, 12, 13],
                                                title: 'Incyte Investigator',
                                            },
                                            {
                                                columns: [14, 15, 16, 17],
                                                title: 'External Investigator',
                                            },
                                        ],
                                        tableProps: {
                                            colOverrides: [
                                                {
                                                    columns: [0],
                                                    hStyles: { width: '28rem' },
                                                    bStyles: {
                                                        color: 'royalblue',
                                                        textDecoration: 'underline',
                                                    },
                                                },
                                                {
                                                    columns: [1],
                                                    hStyles: { width: '12rem' },
                                                },
                                                {
                                                    columns: [2, 3, 4, 5, 6, 7],
                                                    hStyles: { width: '8rem' },
                                                },
                                                {
                                                    columns: [8, 9, 10, 11],
                                                    hStyles: { width: '8rem' },
                                                },
                                                {
                                                    columns: [12, 13, 14, 15, 16, 17],
                                                    hStyles: { width: '7rem' },
                                                },
                                                {
                                                    columns:
                                                        activeTeam === 'HO1'
                                                            ? [18]
                                                            : [16],
                                                    hStyles: { width: '17rem' },
                                                },
                                            ],
                                            colProps: [
                                                {
                                                    // profConnAddon: ( cellData ) => ( <ProfConnectionsAddon column={cellData.column} value={cellData.value} /> ),
                                                },
                                            ],
                                        },
                                    },
                                },
                            ]
                        },
                    ]
                }
            ],
        };
	} else {
		defaultLayout = {
			xs: [{
				w: 24,
				h: 16,
				x: 0,
				y: 0,
				i: 'Message Box',
				props: {
					color: 'orange',
					text: 'Please select one HCP to view the overview.',
				},
				moved: false,
				static: true,
			}],
		};
	}

	const urlSuffix = ( profId ) ? `/${profId}` : '';
    const connectedProf = useRecoilValue(connectedProfileRefState);
    const sideNavIsExpanded = useRecoilValue(sideNavIsExpandedState);
    let offset = sideNavIsExpanded ? 114 : 70;
    let { x, y, height } = connectedProf?.getBoundingClientRect() || {};
    const screenOrientation = useRecoilValue(screenOrientationState);
    useEffect(() => {
        ({ x, y, height } = connectedProf?.getBoundingClientRect() || {});
        offset = sideNavIsExpanded ? 114 : 70;
    }, [screenOrientation, sideNavIsExpanded]);
    const content = (
        <>
            <NavGroupMosaic showClear urlSuffix={urlSuffix} />
            <Grid title="Connections" layout={defaultLayout} />
            <BottomSlider allowOverflow />
            {/* {tempProfId && (
                <ShrinkOutlined
                    rotate={45}
                    style={{
                        fontSize: '22px',
                        color: '#E73564',
                        position: 'absolute',
                        top: `${y + height / 2}px`,
                        left: `${x - offset - 22}px`,
                    }}
                />
            )} */}
            <LeftSlider />
        </>
    );

	return ( content );
};

export default Connections;
