import React, { useContext } from 'react';

import {RGLGrid as Grid, TopNav} from '@trinity-incyte/ui';
import { ConfigContext } from '@trinity-incyte/context';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const ListBuilder = ( props ) => {
    const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360;
	const { appId, items } = config.ids;
  const app = Mosaic.Qlik.app[appId];

	const defaultLayout = {
		"lg":[
			{"w":24,"h":16,"x":0,"y":0,"i":"complex_list_builder","moved":false,"static":true},
	]};

	return (
    <>
      <TopNav
        dropdownText="List Builder"
        dropdownItems={[
          { text: 'List Builder', type: 'header' },
          { text: 'List Builder', to: '/ListBuilder/' },
          { text: 'Segments', to: '/ListBuilder/Segments' },
        ]}
        showClear
        showFilters
        config={config}
      />
      <Grid
        title="List Builder"
        layout={defaultLayout}
      />
    </>
	);
};

export default ListBuilder;
