import React, { useContext } from 'react';
import { RGLGrid, TopNav, NavTerritory } from '@trinity-incyte/ui';
import { ConfigContext } from '@trinity-incyte/context';

const EventsMain = ( props ) => {
	const { activeTab } = props;
  const Config = useContext(ConfigContext);

		// I'm not sure if this is important? When trying to switch to Ant Layout in AppRouter this broke things
	// useEffect(() => {
	// 	(document.querySelector('.container > .segment') as HTMLElement).style.padding = '0px';
	// 	window.dispatchEvent(new Event('resize'));
	// 	return () => {
	// 		// document.querySelector('.container > .segment').style.padding = '14px';
	// 	}
	// }, []);

	const defaultLayout = {
		"lg": [
			{
				"w": 24,
				"h": 16,
				"x": 0,
				"y": 0,
				"i": "/Events/Index",
				props: {
					initialPane: (activeTab === 'Upcoming') ? 1 : 0,
				},
				"moved": false,
				"static": true,
			},
		],
	};

	return (
		<>
			<TopNav
				dropdownText={(<span>Events</span>)}
				dropdownHeader="Events"
				dropdownItems={[
					{ text: 'Events', type: 'header' },
					{ text: 'Recent', to: '/Events/Recent', type: 'item' },
					{ text: 'Upcoming', to: '/Events/Upcoming', type: 'item' },
					{ text: 'Calendar', to: '/Events/Calendar', type: 'item' },
				]}
				config={Config.Qlik.FieldSales}
				showFilters
				showClear
			>
				<NavTerritory />
			</TopNav>
			<RGLGrid
				title="Mosaic Overview"
				layout={defaultLayout}
			/>
		</>
	);
};

export default EventsMain;
