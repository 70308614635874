import React from "react"
import { CSSProperties } from "react"

export const Redacted = ({styleProp = {}, titleProp = null}) => {
    const style:CSSProperties = {...styleProp};
    style.width = '100%';
    style.height = '100%';
    let threeDots = <span style={{color:'#ccc'}}>---</span>
    return (
        <div style={style}>
            {titleProp ? <div className="header-threesixty">{titleProp}</div> : <></>}
            {/* <div style={{paddingLeft:'3px'}}>You do not have permission to view this data.</div> */}
            <table className="basicTable">
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><span style={{color:'#ccc'}}>Data Withheld</span></td>
                    <td>{threeDots}</td>
                    <td>{threeDots}</td>
                    <td>{threeDots}</td>
                </tr>
                </tbody>
            </table>

        </div>
    )
    
}