import moment, { MomentInput } from 'moment';
import { config } from 'process';
import * as pathGenerators from './lib/path-generators'
import React from 'react';
import { useLocation } from "react-router-dom";

declare const window: any;

const __internal = {
    idIndex: 0,
};

export const PathGenerators = pathGenerators;

export const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const generateGetMoreData = () => {
	function s( e, t, n, i ) {
		Object.defineProperties( this, {
			qDimensionInfo: { value: t },
			qText: { value: e.qIsOtherCell ? t.othersLabel : e.qText },
			qElemNumber: { value: e.qElemNumber },
			qState: { value: e.qState },
			col: { value: n },
			selected: {
				get: function e() {
					return this.header.isSelected( this.qElemNumber );
				},
			},
			header: {
				get: function e() {
					return this.parent.headers[this.col];
				},
			},
			parent: { value: i },
		});
		// eslint-disable-next-line no-unused-expressions
		Number.isNaN( +e.qNum ) || Object.defineProperties( this, { qNum: { value: e.qNum } });
	}
	s.prototype.select = function () {
		this.header.toggleSelect( this.qElemNumber );
		if ( this.parent.view && this.parent.view.selectValues ) return this.parent.view.selectValues( this.col, [this.qElemNumber], true );
		return this.parent.model.selectHyperCubeValues( '/'.concat( this.parent.defPath ), this.col, [this.qElemNumber], true );
	};

	function l( e, t, n ) {
		Object.defineProperties( this, {
			qMeasureInfo: { value: t },
			qGrandTotal: { value: n },
			qText: { value: e.qText },
		});
		// eslint-disable-next-line no-unused-expressions
		Number.isNaN( +e.qNum ) || Object.defineProperties( this, { qNum: { value: e.qNum } });
	}
	l.prototype.getPercentOfMax = function () {
		return Math.round( 100 * this.qNum / this.qMeasureInfo.qMax * 100 ) / 100;
	};
	l.prototype.getPercent = function () {
		return Math.round( 100 * this.qNum / this.qGrandTotal.qNum * 100 ) / 100;
	};

	function c( e, t, n ) {
		const i = [];
		const a = [];
		let r = [];
		const o = t.qDimensionInfo.length;
		const c = t.qColumnOrder || t.columnOrder;
		e.forEach(( e, u ) => {
			if ( c ) {
				let d;
				if ( n.headers[u].isDimension ) {
					d = new s( e, n.headers[u], u, n );
					i.push( d );
				} else {
					d = new l( e, n.headers[u], t.qGrandTotalRow[a.length + 1] || '' );
					a.push( d );
				}
				r[u] = d;
			} else {
				u < o ? i.push( new s( e, t.qDimensionInfo[u], u, n )) : a.push( new l( e, t.qMeasureInfo[u - o], t.qGrandTotalRow[u - o]));
				r = i.concat( a );
			}
		});
		Object.defineProperties( this, {
			dimensions: { value: i },
			measures: { value: a },
			cells: { value: r },
		});
	}

	function u( e, t ) {
		t && t.forEach(( t ) => {
			t.qMatrix.forEach(( t ) => {
				e.rows.push( new c( t, e.qHyperCube, e ));
			});
		});
	}

	const retVal = function ( pageHeight? ) {
		if ( this.requestActive ) return;
		if ( this.rowCount <= this.rows.length ) return;
		this.requestActive = true;
		const e = this;
		const t = {
			qTop: this.rows.length,
			qLeft: 0,
			qWidth: this.colCount,
			qHeight: pageHeight || 250, // THIS IS THE IMPORTANT PART!!!
		};
		if ( e.view ) {
			return e.view.backendApi.getData([t]).then(( t ) => {
				u( e, t );
				e.requestActive = false;
				e.OnData.emit();
			});
		}
		if (e.qHyperCube) {
			e.model.getHyperCubeData( '/qHyperCubeDef', [t]).then(( t ) => {
				u( e, t );
				e.requestActive = false;
				e.OnData.emit();
			});
		}
	};

	return retVal;
};

export const convertTeam = (team) => {
	const nameToTeam = {
		'HO1': 11,
		'HO2': 12,
		'HO3': 21,
		'OCNE': 61,
		'BASE': 31,
		'OVERLAY': 32,
	};	
	const teamToName = {
		11: 'HO1',
		12: 'HO2',
		21: 'HO3',
		61: 'OCNE',
		31: 'BASE',
		32: 'OVERLAY',
	};	
	return nameToTeam[team] ?? teamToName[team]
};

export const getPrimaryBrandForTeam = (teamToBrand, teamName) => {
	const primaryBrandObj =  teamToBrand.find(element => element.teamName===teamName);
	if (primaryBrandObj===undefined) return false;
	return primaryBrandObj.primaryBrand;
}

export const hasFeature = (features, featureName) => {
	return (features.some(element => element.featureName===featureName));
}

export const teamHasFeature = (features, featureName, teamName) => {
	return (features.some(element => element.featureName===featureName && element.teams?.includes(teamName)));
}

export const brandHasFeature = (features, featureName, brandName) => {
	return (features.some(element => element.featureName===featureName && element.brands?.includes(brandName)));
}


export const teamHasBrand = (teamToBrand, teamName, brandName) => {
	return (teamToBrand.some(element => element.teamName===teamName && element.brands.includes(brandName)));
}

export const getTeamBrands = (teamToBrand, teamName) => {
	let teamBrands = teamToBrand.find(element => element.teamName === teamName)?.brands ?? [];
	teamBrands = teamBrands.filter(element => element);
	return teamBrands;
}

// find one report from config
export const findReportByTeam = (reports, teamName, reportType) => reports[reportType].find(({teams}) => teams.includes(teamName));

export const toTitleCase = (string) => {
	if (string==undefined || string.length<1) return string;
	return string[0]?.toUpperCase() + string.slice(1)?.toLowerCase();
}

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
	mobileS: `(min-width: ${sizes.mobileS})`,
	mobileM: `(min-width: ${sizes.mobileM})`,
	mobileL: `(min-width: ${sizes.mobileL})`,
	tablet: `(min-width: ${sizes.tablet})`,
	laptop: `(min-width: ${sizes.laptop})`,
	laptopL: `(min-width: ${sizes.laptopL})`,
	desktop: `(min-width: ${sizes.desktop})`,
};

const Utils = {
    moment: (date?: MomentInput, formatString?: string, strict?:boolean) => {
        if (date) {
            return moment(date, formatString, strict);
        } else {
            return moment();
        }
    },
    idGen: (type) => {
        __internal.idIndex += 1;
        return `${type}_${__internal.idIndex}`;
    },
    isTransplantCenterSelected: (selections: any = false, strict = false, config) => {
        let value = false;
        const { transplant_centers } = config.ids.fields;
        if (selections && selections.length !== 0) {
            let val;
            for (let ii = 0; ii < selections.length; ii += 1) {
                val = selections[ii];
                if (val.fieldName === transplant_centers) {
                    if (strict === true && val.selectedValues.length === 1) {
                        value = true;
                    } else if (strict === false) {
                        value = true;
                    }
                }
            }
        }
        return value;
    },
    remtoPX: function (value) {
        return parseFloat(getComputedStyle(document.documentElement).fontSize) * value;
    },
    getPXValue: function (value) {
        if (!isNaN(value)) {
            return value;
        } else if (value.split('rem').length === 2) {
            return this.remtoPX(value.split('rem')[0]);
        } else if (value.split('px').length === 2) {
            return value.split('px')[0];
        } else {
            console.error('provide widths and heights of table properties in px or rem only', value);
        }
    },
    randomDate: (start: Date, end: Date) => {
        return new Date(
            start.getTime() + Math.random() * (end.getTime() - start.getTime())
        );
    },
    screen: (Config) => {
        const height = window.innerHeight;
        const width = window.innerWidth;
        const { userAgent } = window.navigator;
        const orientation = ( window.matchMedia('(orientation:portrait)').matches ) ? 'PORTRAIT' : 'LANDSCAPE';
        const viewH = (window.visualViewport) ? Math.floor(window.visualViewport.height / 100 ) : Math.floor( window.innerHeight / 100 );
        const viewW = (window.visualViewport) ? Math.floor(window.visualViewport.width / 100 ) : Math.floor( window.innerWidth / 100 );
        const gridH = (window.visualViewport) ? Math.floor(window.visualViewport.height / Config.App.grid.vUnits ) : Math.floor( window.innerHeight / Config.App.grid.vUnits );
        const gridW = (window.visualViewport) ? Math.floor(window.visualViewport.height / Config.App.grid.vUnits ) : Math.floor( window.innerHeight / Config.App.grid.vUnits );

        let returnVal = {
            class: 'DESKTOP',
            device: 'UNKNOWN',
            extra: 'UNKNOWN',
            orientation: orientation,
            height: height,
            width: width,
            userAgent: userAgent,
            viewH: viewH,
            viewW: viewW,
            gridH: gridH,
            gridW: gridW,
        };

        if ( !height || !width ) {
            returnVal.class = 'SERVER';
        }

        // Set device class
        if ( width <= 640 ) {
            returnVal.class = 'MOBILE';
        } else if ( width > 640 && width <= 1007 ) {
            returnVal.class = 'TABLET';
        } else if ( width > 1007 ) {
            returnVal.class = 'DESKTOP';
        } else {

        }

        // Exact match on Incyte devices
        if ( height === 610 && width === 1280 && userAgent.indexOf( 'Windows NT 10.' ) !== -1 ) {
            returnVal.device = 'INCYTE';
        } else if ( height === 1260 && width === 1024 && orientation === 'PORTRAIT' ) {
            returnVal.class = 'TABLET';
            returnVal.device = 'INCYTE';
        } else if ( height === 918 && width === 1366 && orientation === 'LANDSCAPE' ) {
            returnVal.class = 'TABLET';
            returnVal.device = 'INCYTE';
        }

        return returnVal;
    },
    generateGetMoreData
};

export const editQueryParameter = (key, value, history, location) => {
    const searchParams = new URLSearchParams(location.search);

    // Update or add the query parameter
    searchParams.set(key, value);

    // Generate the new search string with updated query parameters
    const newSearch = '?' + searchParams.toString();

    // Replace the current URL with the updated search string
    history.replace({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  export const removeQueryParameter = (key, history, location) => {
    const searchParams = new URLSearchParams(location.search);

    // Remove the query parameter
    searchParams.delete(key);

    // Generate the new search string without the removed query parameter
    const newSearch = '?' + searchParams.toString();

    // Replace the current URL with the updated search string
    history.replace({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  export const saveURIAs = (uri, filename) => {
	var link = document.createElement('a');
	if (typeof link.download === 'string') {
	  link.href = uri;
	  link.download = filename;
  
	  //Firefox requires the link to be in the body
	  document.body.appendChild(link);
	  
	  //simulate click
	  link.click();
  
	  //remove the link when done
	  document.body.removeChild(link);
	} else {
	  window.open(uri, "_blank");
	}
  }

export default Utils;
