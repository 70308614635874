/* eslint-disable no-underscore-dangle */
import HCO360 from './HCO360';
import HCP360 from './HCP360';
import HCP360JakafiOverview from './HCP360JakafiOverview';
import FieldSales from './FieldSales';
import Org from './Org';
import Other from './Other';
import HCP360JakafiTrends from './HCP360JakafiTrends';
import HCP360MonjuviOverview from './HCP360MonjuviOverview';
import HCP360PemazyreOverview from './HCP360PemazyreOverview';
import HCP360MonPemTrends from './HCP360MonPemTrends';
import HCP360NiktimvoOverview from './HCP360NiktimvoOverview';
import HCP360NiktimvoTrends from './HCP360NiktimvoTrends';

const Library: any = {
  HCO360,
  HCP360,
  HCP360JakafiOverview,
  HCP360JakafiTrends,
  HCP360MonjuviOverview,
  HCP360MonPemTrends,
  HCP360NiktimvoOverview,
  HCP360NiktimvoTrends,
  HCP360PemazyreOverview,
  FieldSales,
  Org,
  Other,
};

Library.__itemKeys = [];
Library.__itemPaths = {}; // 'key': '/path/to/item'

// This should probably be recursive at some point
const libraryKeys = Object.keys( Library );
libraryKeys.forEach(( key ) => {
  if ( key.indexOf( '__' ) === 0 ) return;
  const subKeys = Object.keys( Library[key]);
  subKeys.forEach(( subKey ) => {
    Library.__itemPaths[subKey] = `${key}.${subKey}`;
    Library.__itemKeys.push(subKey);
  });
});

Library.getItem = ( name ) => {
  if ( !name ) return null;

  let retVal;
  let pathSplit;
  if ( name.indexOf( '.' ) !== -1 ) {
    pathSplit = name.split( '.' );
  } else if ( Library.__itemKeys.indexOf( name ) !== -1 ) {
    pathSplit = Library.__itemPaths[name].split( '.' );
  }
  if ( !pathSplit ) {
    console.warn(`can't load: ${name}`);
    return null;
  }
  pathSplit.forEach(( subPath ) => { // Easy recursive
    if ( !retVal ) {
      retVal = Library[subPath];
    } else if ( retVal[subPath]) {
      retVal = retVal[subPath];
    } else {
      return;
    }
  });

  return retVal;
};

export default Library;
