import React from "react";
import { KPIBox, QSMashupObject, Redacted } from "@trinity-incyte/ui";
import Config from "../../Config";
import { useRecoilValue } from "recoil";
import { activeTeam as activeTeamAtom, QSAppMetadataFamily, QlikUser as QlikUserAtom } from "@trinity-incyte/recoil";
import { FieldSalesPathGenerator } from "libs/utils/src/lib/path-generators";

const config = Config.Qlik.Prof360;
const { appId } = config.ids;

export const HCP360MonPemTrends = {
    'MONPEM Trends Sales': {
        component: () => {
            const QlikUser = useRecoilValue(QlikUserAtom);
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const activeTeam = useRecoilValue(activeTeamAtom);

            const suppressSalesData = QlikUser?.suppressSalesData;
            const canClick = !suppressSalesData;
            const height = suppressSalesData ? 'auto' : '400px';
            return (
                <div style={{width: '100%', display:'flex'}}>
                    <KPIBox canClick={canClick} to={FieldSalesPathGenerator(activeTeam,'/Monjuvi/Details/Account%20Order%20History')} style={{height: height, display: 'flex', width: '50%'}}>
                        {suppressSalesData ? <Redacted titleProp={ids.get('Mosaic_HCP 360 - Trends_Monjuvi Sales in Zip Code')?.description} styleProp={{flex:1}} /> :
                            <QSMashupObject
                                exportAsImg
                                exportAsPPT
                                exportAsXLS
                                key={`Mosaic_HCP 360 - Trends_Monjuvi Sales in Zip Code`}
                                appId={appId}
                                mashupId={ids.get('Mosaic_HCP 360 - Trends_Monjuvi Sales in Zip Code')?.id}
                                contentClass="no-margin"
                                contentStyle={{ height: null, width: '100%', padding: '15px' }}
                                style={{flex:1}}
                            />
                        }
                    </KPIBox>
                    <KPIBox canClick={canClick} to={FieldSalesPathGenerator(activeTeam,'/Pemazyre/Details/SP%20Sales%20History')} style={{height: height, display: 'flex', width: '50%'}}>
                        {suppressSalesData ? <Redacted titleProp={ids.get('Mosaic_HCP 360 - Trends_Pemazyre SP and Direct Sales in Zip Code')?.description} styleProp={{flex:1}} /> :
                            <QSMashupObject
                                exportAsImg
                                exportAsPPT
                                exportAsXLS
                                key={`Mosaic_HCP 360 - Trends_Pemazyre SP and Direct Sales in Zip Code`}
                                appId={appId}
                                mashupId={ids.get('Mosaic_HCP 360 - Trends_Pemazyre SP and Direct Sales in Zip Code')?.id}
                                contentClass="no-margin"
                                contentStyle={{ height: null, width: '100%', padding: '15px' }}
                                style={{flex:1}}
                            />
                        }
                    </KPIBox>
                </div>
            );
        }
    },
    'MONPEM Trends Alerts': {
        component: () => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            const activeTeam = useRecoilValue(activeTeamAtom);
            return (
                <div style={{width: '100%'}}>
                    <KPIBox canClick to={FieldSalesPathGenerator(activeTeam,'/AlertsFollowUp/')} style={{height: '400px', display: 'flex', width: '100%'}}>
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Mon Pem Alerts Received vs Followed Up`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Mon Pem Alerts Received vs Followed Up')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Mon Pem Alerts Followed Up by Channel`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Mon Pem Alerts Followed Up by Channel')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                    </KPIBox>
                </div>
            );
        }
    },
    'MONPEM Trends Interactions': {
        component: (props) => {
            const metadata = useRecoilValue(QSAppMetadataFamily(appId));
            const { IDsTable: ids } = metadata;
            return (
                <div style={{width: '100%'}}>
                    <KPIBox canClick to={`/HCP360/IncyteActivity/Interactions/${props.profId}`} style={{height: '400px', display: 'flex', width: '100%'}}>
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Mon Pem Emails HQ vs RTE`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Mon Pem Emails HQ vs RTE')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                        <QSMashupObject
                            exportAsImg
                            exportAsPPT
                            exportAsXLS
                            key={`Mosaic_HCP 360 - Trends_Mon Pem Call Direct vs Influenced`}
                            appId={appId}
                            mashupId={ids.get('Mosaic_HCP 360 - Trends_Mon Pem Call Direct vs Influenced')?.id}
                            contentClass="no-margin"
                            contentStyle={{ height: null, width: '100%', padding: '15px' }}
                            style={{flex:1}}
                        />
                    </KPIBox>
                </div>
            );
        }
    },
}

export default HCP360MonPemTrends;