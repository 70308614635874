import React, { ReactNode, useMemo, useState } from "react";
import {
	QlikUser as qlikUserAtom,
	activeTeam as activeTeamAtom,
} from '@trinity-incyte/recoil';
import { useRecoilValue } from "recoil";
import Utils from '@trinity-incyte/utils';
import FilterButtons from "../filter-buttons/filter-buttons";
import { CaretDownFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CenterV } from "../../boxes/CenterV";

export interface TopNavProps {
    children?: ReactNode
    dropdownHeader?: string
    dropdownText?: ReactNode
    dropdownItems?: Array<TopNavMenuItem>
    showClear?: boolean
    showFilters?: boolean
    showLastRX?: boolean | string
    attached?: ReactNode
    dataDate?: string
    dataDateLabel?: string
    dataDateDerm?: string
    dataDateJakPem?: string
    dataDateMon?: string
    config: any
}

export function TopNav(props: TopNavProps) {
	const userData = useRecoilValue(qlikUserAtom);
	const activeTeam = useRecoilValue(activeTeamAtom);
	const dataDateJakPem = userData.dataDate.jakPem;
	const dataDateDerm = Utils.moment(new Date(userData.dataDate.derm)).format('MM/DD/YYYY');
	const dataDateMon = userData.dataDate.mon;

	const lastDateLabel = props.dataDateLabel != null 
		? props.dataDateLabel
		: props.showLastRX === true
			? 'Last Rx'
			: props.showLastRX

	const lastRxDate = props.dataDate != null
		? props.dataDate
		: dataDateJakPem == null
			? dataDateDerm
			: (activeTeam === 'HO3')
				? dataDateMon
				: dataDateJakPem
    return (
        <div style={{height: '63px', width: '100%', display: "flex", flexDirection: "row", position: "relative", backgroundColor: "black"}}>
            {(props.dropdownText && props.dropdownItems) && <TopNavDropdown header={props.dropdownHeader} text={props.dropdownText} items={props.dropdownItems}></TopNavDropdown>}
            {props.children}
            <div style={{flex: 1}}></div>
            {(props.showLastRX) && <CenterV className="topNavItem">
                <div className="static-topNav-item" style={{ fontSize: '1.2rem', color: '#FFFFFFE6' }}>{` ${lastDateLabel}: ${lastRxDate}`}</div>
            </CenterV>}
            {(props.attached) && <div>
                {props.attached}
            </div>}
            {(props.showClear || props.showFilters) && <div>
                <FilterButtons config={props.config} hideClear={!props.showClear} hideFilters={!props.showFilters} />
            </div>}
        </div>
    )
}

export interface TopNavMenuItem {
    onClick?: () => void
    key?: string
    text: string
    to?: string
    type?: string
}

export interface TopNavDropdownProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    header?: string
    items: Array<TopNavMenuItem>
    text: ReactNode
}
export function TopNavDropdown(props: TopNavDropdownProps) {
	const history = useHistory()
    const [showMenu, setShowMenu] = useState(false)
    const onMenuItemClick = (menuItem) => {
        menuItem.onClick?.()
        if (menuItem.to) {
            setShowMenu(false)
            history.push(menuItem.to)
        }
    }
    const menu = useMemo(() => {
        const list = props.items.map((item) => {
            return <div 
                key={item.key} 
                onClick={() => {onMenuItemClick(item)}}
                className={item.type}
            >
                {item.text}
            </div>
        })
        return (
            <div 
                style={{whiteSpace: "nowrap", maxWidth: '80vw', position: "absolute", top: "100%", zIndex: 10}}
                className="menu"
            >
                {list}
            </div>
        )
        
    }, [props.header, props.items, props.text])

    const style = {...props.style}
    style.height = "63px"
    style.display = "flex"
    style.flexDirection = "column"
    style.padding = "0 5px"
    style.position = "relative"
    
    return (
        <div 
            style={style}
            onClick={() => {
                setShowMenu(!showMenu)
            }}
            className={"topNavItem header interactable" + (showMenu ? " active" : "") }
        >
            <div style={{flex: 1}}></div>
            <div style={{display: "flex", flexDirection: "row", flexFlow: "1"}}>
                <CaretDownFilled style={{height: "14px", margin: "auto", padding: "0 5px", fontSize: "14px"}}></CaretDownFilled>
                <div style={{paddingRight: "8px"}}>{props.text}</div>
            </div>
            <div style={{flex: 1}}></div>
            {showMenu && menu}
        </div>
    )
}
