import React from "react"

export interface CenterVProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {

}

export const CenterV = (props: CenterVProps) => {
    const style = {...props.style}
    style.display = "flex"
    style.flexDirection = "column"

    return (
        <div className={props.className} style={style} key={props.key} onClick={props.onClick}>
            <div style={{flex: 1}}></div>
            {props.children}
            <div style={{flex: 1}}></div>
        </div>
    )
}