import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import {RGLGrid as Grid, LeftSlider, NavGroupMosaic } from '@trinity-incyte/ui';
import { use360ProfSelection } from '@trinity-incyte/hooks';
import { ConfigContext } from '@trinity-incyte/context';
import { QlikUser as QlikUserAtom } from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
declare const Mosaic: MosaicGlobal;
const HCP360Layout = () => {
	const QlikUser = useRecoilValue(QlikUserAtom);
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360,
	{ appId } = config.ids,
	app = Mosaic.Qlik.app[appId];
	use360ProfSelection('');

	const defaultLayout = {
		"lg": [{
			"w": 24,
			"h": 16,
			"x": 0,
			"y": 0,
			"i": "Mosaic Search",
			props: {
				app,
				hideGeo: QlikUser?.features?.APP.HCP360Permitted
			},
			"moved": false,
			"static": true
		}]
	};

	const content = (
		<>
			<NavGroupMosaic />
			<Grid
				title="Mosaic Search"
				layout={defaultLayout}
			/>
			<LeftSlider />
		</>
	);

	return ( content );
};

export default HCP360Layout;
